@charset "utf-8";

// Define defaults for each variable.

$base-font-family: Anonymous Pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;
$spacing-unit:     30px !default;

// scheme
$green-s1:  #b5bd68;
$red-s1:    #cc6666;
$yellow-s1: #f0c674;
$cyan-s1:   #8abeb7;
$blue-s1:   #81a2be;
$bg-s1:     #1d1f21 !default;
$fg-s1:     #ffffff !default;

// $text-color:       #111 !default;
// $background-color: #fdfdfd !default;
// $brand-color:      #2a7ae2 !default;
$text-color:       $fg-s1;
$background-color: $bg-s1;
$brand-color:      $green-s1 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1024px !default;
$on-palm:          600px  !default;
$on-laptop:        800px  !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
